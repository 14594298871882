//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    delDocumentBill,
    documentArchivedList,
    documentBillAuditPage,
    documentBillIndexPage,
} from '@/api/modular/fileStream/documentManage'
import { PullRefresh } from 'vant'
import item from '../../components/item'
import searchBox from '../../components/searchBox'
export default {
    components: {
        item,
        searchBox,
        PullRefresh,
    },
    props: {
        approvalTab: {
            type: Number | String,
            default: 3,
        },
        tabType: {
            type: Number | String,
            default: 1,
        },
    },
    watch: {
        tabType: {
            handler() {
                this.refresh()
            },
        },
    },
    data() {
        return {
            loading: false,
            finished: false,
            queryParam: {
                approvalTab: 2,
            },
            active: 2,
            pageNo: 1,
            acceptList: [], // 接收
            approvalList: [], // 審批
            archivedList: [], // 文書
            pageSize: 10,
            isRefresh: false,
            list: [],
        }
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageNo++
            this.getBillList()
        },
        refresh() {
            this.pageNo = 1
            this.list = []
            this.pageSize = 10
            this.getBillList()
        },
        senpi({ approvalId, nodeId, id }) {
            this.$router.push({ name: 'mobileApproval', query: { id, approvalId, nodeId } })
        },
        see({ id, documentType, billId }) {
            if (this.tabType == 3) {
                id = billId
            }
            this.$router.push({ name: 'showMobileApproval', query: { id, type: documentType, tabType: this.tabType } })
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deal({ id }) {
            this.$router.push({ name: 'showMobileApproval', query: { id, todo: 'dealWith' } })
        },
        edit(record) {
            this.$router.push({ name: 'mobileBillEdit', query: { id: record.id } })
        },
        addApproval({ id }) {
            this.$router.push({ name: 'mobileCreateApproval', query: { id } })
        },
        onSearch() {
            this.refresh()
        },
        getBillListApi(data) {
            if (this.tabType == 2) {
                return documentBillIndexPage(data)
            }
            if (this.tabType == 3) {
                return documentArchivedList(data)
            }
            return documentBillAuditPage(data)
        },
        // 獲取已接收列表
        getBillList() {
            const formatDraftId = (id) => {
                if (!id) return []
                return JSON.parse(id)
            }
            this.getBillListApi(
                Object.assign(
                    {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                    },
                    this.queryParam
                )
            )
                .then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    this.finished = data.rows.length < this.pageSize
                    if (this.tabType == 1) {
                        if (this.pageNo == 1) {
                            this.approvalList = [...data.rows]
                        } else {
                            this.approvalList = [...this.approvalList, ...data.rows]
                        }
                        // this.list = this.approvalList
                        this.$set(this, 'list', this.approvalList)
                    }
                    if (this.tabType == 2) {
                        if (this.pageNo == 1) {
                            this.acceptList = [...data.rows]
                        } else {
                            this.acceptList = [...this.acceptList, ...data.rows]
                        }
                        // this.list = this.acceptList
                        this.$set(this, 'list', this.acceptList)
                    }
                    if (this.tabType == 3) {
                        if (this.pageNo == 1) {
                            this.archivedList = [...data.rows]
                        } else {
                            this.archivedList = [...this.archivedList, ...data.rows]
                        }
                        // this.list = this.archivedList
                        this.$set(this, 'list', this.archivedList)
                    }
                })
                .finally(() => {
                    this.loading = false
                    setTimeout(() => {
                        this.isRefresh = false
                    }, 1000)
                })
        },
        // 獲取已接收列表
    },
}
